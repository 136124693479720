<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"

			@change="getSearch(1)"
			@click="getSearch(1)"
			@toExcel="toExcel"
		>
		</Search>

		<div
			class="mt-10 pa-10 bg-white full-height"
		>
			<div
				v-if="item_list.length > 0"
			>
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="150px" />
					</colgroup>
					<thead>
						<tr>
							<th>No</th>
							<th>아이디</th>
							<th>이름</th>
							<th>사용 구분</th>
							<th>적립 구분</th>

							<th>지급 여부</th>
							<th>포인트</th>
							<th>누적 포인트</th>
							<th>등록일</th>
							<th>비고</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in item_list"
							:key="item.uid"
						>
							<td>{{ item.no }}</td>
							<td>{{ item.member_id }}</td>
							<td>{{ item.member_name }}</td>
							<td>{{ item.use_div_name }}</td>
							<td :class="'color-' + item.p_status_color">{{ item.p_status_name }}</td>

							<td :class="'color-' + item.use_type_color">{{ item.use_type_name }}</td>
							<td :class="'color-' + item.use_type_color">{{ item.use_point | makeComma}}P</td>
							<td>{{ item.point_now | makeComma}}P</td>
							<td>{{ item.wDate }}</td>

							<td></td>
						</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Excel from "@/components/Excel";
import Search from "@/view/Layout/Search";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'MemberPoint'
	, components: { Empty, Search, Excel, Pagination}
	, props: ['user', 'codes']
	,data: function (){
		return {
			program: {
				name: '회원 포인트 내역'
				,top: true
				,title: true
			}
			,search: this.$storage.init({
				search_type: 'member_name'
				, admin_code: ''
				, member_status: ''
				, list_cnt: 10
				, page: 1
				, tCnt: 0
				, use_div: ''
				, p_status: ''
				, use_type: ''
				, sDate: this.$date.getMonthlyDate('', '-').start
				, eDate: this.$date.getToday('-')
			})
			,search_option:{
				is_excel: true
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,sDate: true
				,eDate: true
				,search_type: [
					{ name: '아이디', column: 'member_id'}
					,{ name: '이름', column: 'member_name'}
				]
				,select: [
					{ name: '사용 구분', column: 'use_div', items: [
							{ name: '포인트 충전', column: 'charge'}
							,{ name: '포인트 결제', column: 'order'}
							,{ name: '포인트 선물', column: 'present'}
							,{ name: '관리자 지급', column: 'admin'}
							,{ name: '충전 결제 취소', column: 'cancel'}
							,{ name: '주문 결제 취소', column: 'o_cancel'}
						]
					}
					, { name: '적립 구분', column: 'use_type', items: [
							{ name: '적립', column: '+'}
							,{ name: '차감', column: '-'}
						]
					}
					, { name: '지급 여부', column: 'p_status', items: [
							{ name: '지급 대기', column: 'w'}
							,{ name: '지급 완료', column: 's'}
							,{ name: '지급 취소', column: 'e'}
						]
					}
				]
			}
			,items: []
			,is_excel: false
			,excel_data: {
				name: '회원포인트 내역'
				,header: [
					{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '이름', column: 'member_name'}
					,{ key: 0, name: '사용 구분', column: 'use_div_name'}
					,{ key: 0, name: '적립 구분', column: 'use_type_name'}
					,{ key: 0, name: '지급 여부', column: 'p_status_name'}
					,{ key: 0, name: '포인트', column: 'use_point'}
					,{ key: 0, name: '누적 포인트', column: 'point_now'}
					,{ key: 0, name: '등록일', column: 'wDate'}
					,{ key: 0, name: '비고', column: 'admin_id'}
				]
				,content: null
			}
			,is_item : false
			, items_agency: []
		}
	}
	,computed: {
		item_list: function (){
			return this.items.filter( (item, index) => {
				item.no = this.search.tCnt - (this.search.page * this.search.list_cnt - this.search.list_cnt) - index
				return item
			})
		}
	}
	,methods: {
		getData: async function(){

			this.is_item = false
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getMemberPointList'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'tCnt', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
				this.is_excel = false
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toExcel: function(){
			this.is_excel = true
			this.excel_data.content = this.items
		}
		, getSearch: function(page){
			this.search.page = page
			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
	}
}
</script>